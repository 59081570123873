<div class="authentication-bg min-vh-100">
  <div class="bg-overlay bg-white"></div>
  <div class="container">
    <div class="d-flex flex-column min-vh-100 px-3 pt-4">
      <div class="row justify-content-center my-auto">
        <div class="col-lg-10">

          <div class="py-5">
            <div class="card auth-cover-card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="auth-img">
                  </div>
                </div><!-- end col -->
                <div class="col-lg-6">
                  <div class="p-4 p-lg-5 bg-primary h-100 d-flex align-items-center justify-content-center">
                    <div class="w-100">
                      <div class="mb-4 mb-md-5">
                        <a routerLink="/" class="d-block auth-logo">
                          <img src="assets/images/logo-white.png" alt="logo" class="logo">
                        </a>
                      </div>
                      <div class="text-white-50 mb-4">
                        <h5 class="text-white">Register Account</h5>
                        <p>Get your free Dashonic account now.</p>
                      </div>
                      <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
                        <div class="form-floating form-floating-custom mb-3">
                          <input type="email" class="form-control" id="input-email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f['email'].errors }" placeholder="Enter Email">
                          <label for="input-email">Email</label>
                          <div class="form-floating-icon">
                            <i class="uil uil-envelope-alt"></i>
                          </div>
                          <div *ngIf="submitted && f['email'].errors" class="invalid-feedback" align="left">
                            <div *ngIf="f['email'].errors['required']">Email is required</div>
                            <div *ngIf="f['email'].errors['email']">Email must be a valid email address</div>
                          </div>
                        </div>

                        <div class="form-floating form-floating-custom mb-3">
                          <input type="text" class="form-control" id="input-username" formControlName="name" [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" placeholder="Enter name">
                          <label for="input-username">Username</label>
                          <div class="form-floating-icon">
                            <i class="uil uil-users-alt"></i>
                          </div>
                          <div *ngIf="submitted && f['name'].errors" class="invalid-feedback" align="left">
                            <div *ngIf="f['name'].errors['required']">Username is required</div>
                          </div>
                        </div>

                        <div class="form-floating form-floating-custom mb-3">
                          <input type="password" class="form-control" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon">
                          <label for="input-password">Password</label>
                          <div class="form-floating-icon">
                            <i class="uil uil-padlock"></i>
                          </div>
                          <div *ngIf="submitted && f['password'].errors" class="invalid-feedback" align="left">
                            <div *ngIf="f['password'].errors['required']">Password is required</div>
                          </div>
                        </div>

                        <div class="text-white-50">
                          <p>By registering you agree to the Dashonic <a href="javascript:void(0);" class="text-white">Terms of Use</a></p>
                        </div>

                        <div class="mt-4">
                          <button class="btn btn-info w-100" type="submit">Register</button>
                        </div>
                      </form><!-- end form -->
                    </div>
                  </div>
                </div><!-- end col -->
              </div>
            </div><!-- end card-->
            <div class="mt-5 text-center text-muted">
              <p>Already have an account ? <a routerLink="/account/login" class="fw-medium text-decoration-underline"> Signin </a></p>
            </div>
          </div>
        </div><!-- end col -->
      </div><!-- end row -->
    </div>
  </div>
  <!-- end container -->
</div>
