import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {UserService} from "../../core/services/user.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

/**
 * Login Component
 */
export class LoginComponent implements OnInit {

  // set the currenr year
  year: number = new Date().getFullYear();
  submitted = false;
  loading = false;
  returnUrl!: string;
  error = '';
  hide = true;
  loginForm!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private userService: UserService
  ) {
  }

  ngOnInit(): void {
    /**
     * Form Validatyion
     */
    this.loginForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      password: ['', Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  /**
   * Form submit
   */
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    const user = this.userService.getEmptyLoginForm();
    user.password = this.f['password'].value;
    user.username = this.f['name'].value;

    this.userService.login(user).subscribe({
      next: (res) => {
        if (res && !res.error) {
          const token = this.userService.getToken();
          if (token) {
            this.router.navigate(['/analytics']);
          } else {
            this.error = 'Invalid Login';
          }
        } else {
          this.error = res.error || 'Invalid Login';
        }
      },
      error: (error) => {
        this.error = error.message || 'Invalid Login';
        this.submitted = false;
        this.loading = false;
      },
    });
  }
}
