<div class="authentication-bg min-vh-100">
  <div class="bg-overlay bg-white"></div>
  <div class="container">
    <div class="d-flex flex-column min-vh-100 px-3 pt-4">
      <div class="row justify-content-center my-auto">
        <div class="col-lg-10">

          <div class="py-5">
            <div class="card auth-cover-card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="auth-img">
                  </div>
                </div><!-- end col -->
                <div class="col-lg-6">
                  <div
                    class="p-4 p-lg-5 bg-primary h-100 d-flex align-items-center justify-content-center">
                    <div class="w-100">
                      <div class="mb-4 mb-md-5">
                        <a routerLink="/" class="d-block auth-logo">
                          <img src="assets/images/logo-light.png" alt="">
                        </a>
                      </div>
                      <div class="text-white-50 mb-4">
                        <h5 class="text-white">Reset Password</h5>
                        <p>Re-Password with Dashonic.</p>
                      </div>
                      <div class="alert alert-success text-center mb-4" role="alert">
                        Enter your Email and instructions will be sent to you!
                      </div>
                      <form>
                        <div class="form-floating form-floating-custom mb-3">
                          <input type="email" class="form-control" id="input-email"
                                 placeholder="Enter Email">
                          <label for="input-email">Email</label>
                          <div class="form-floating-icon">
                            <i class="uil uil-envelope-alt"></i>
                          </div>
                        </div>

                        <div class="mt-3">
                          <a routerLink="/account/forgot-password"
                             class="btn btn-info w-100">Send Request</a>
                        </div>
                      </form><!-- end form -->
                    </div>
                  </div>
                </div><!-- end col -->
              </div><!-- end row -->
            </div><!-- end card -->
          </div>
        </div><!-- end col -->
      </div><!-- end row -->
    </div>
  </div><!-- end container -->
</div>
