<div class="authentication-bg min-vh-100">
  <div class="bg-overlay bg-white"></div>
  <div class="container">
    <div class="d-flex flex-column min-vh-100 px-3 pt-4">
      <div class="row justify-content-center my-auto">
        <div class="col-lg-10">

          <div class="py-5">
            <div class="card auth-cover-card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="auth-img">
                  </div>
                </div><!-- end col -->
                <div class="col-lg-6">
                  <div class="p-4 p-lg-5 bg-primary h-100 d-flex align-items-center justify-content-center">
                    <div class="w-100">
                      <div class="mb-4 mb-md-5">
                        <a routerLink="/" class="d-block auth-logo">
                          <img src="assets/images/logo-white.png" alt="" class="logo">
                        </a>
                      </div>
                      <div class="text-white-50 mb-4">
                        <h5 class="text-white">Forgot Password</h5>
                        <p>Forgot Your Password?</p>
                      </div>
                      <form [formGroup]="forgotForm" (ngSubmit)="onSubmit()">

                        <div class="form-floating form-floating-custom mb-3">
                          <input type="password" class="form-control" id="input-newpassword" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f['password'].errors }" placeholder="Enter New Password">
                          <label for="input-username">New Password</label>
                          <div class="form-floating-icon">
                            <i class="uil uil-padlock"></i>
                          </div>
                          @if(submitted && f['password'].errors){
                            <div class="invalid-feedback" align="left">
                              @if(f['password'].errors['required']){
                                <div>New Password is required
                                </div>}
                            </div>}
                        </div>

                        <div class="form-floating form-floating-custom mb-3">
                          <input type="password" class="form-control" formControlName="confirm_password" [ngClass]="{ 'is-invalid': submitted && f['confirm_password'].errors }" placeholder="Enter Confirm password" aria-label="confirm_password" aria-describedby="password-addon">
                          <label for="input-password">Confirm Password</label>
                          <div class="form-floating-icon">
                            <i class="uil uil-check-circle"></i>
                          </div>
                          @if(submitted && f['confirm_password'].errors){
                            <div class="invalid-feedback" align="left">
                              @if(f['confirm_password'].errors['required']){
                                <span>Confirm
                                                            Password is required</span>}
                            </div>}
                        </div>

                        <div class="mt-3">
                          <button class="btn btn-info w-100" type="submit">Reset</button>
                        </div>
                      </form><!-- end form -->
                    </div>
                  </div>
                </div><!-- end col -->
              </div><!-- end row -->
            </div><!-- end card-->
            <div class="mt-5 text-center text-muted">
              <p>Remember It ? <a routerLink="/account/login" class="fw-medium text-decoration-underline"> Sign In </a></p>
            </div>
          </div>

        </div><!-- end col -->
      </div><!-- end row -->

    </div>
  </div><!-- end container -->
</div>
