<div class="authentication-bg min-vh-100">
  <div class="bg-overlay bg-white"></div>
  <div class="container">
    <div class="d-flex flex-column min-vh-100 px-3 pt-4">
      <div class="row justify-content-center my-auto">
        <div class="col-lg-10">
          <div class="py-5">
            <div class="card auth-cover-card overflow-hidden">
              <div class="row g-0">
                <div class="col-lg-6">
                  <div class="auth-img">
                  </div>
                </div><!-- end col -->
                <div class="col-lg-6">
                  <div class="p-4 p-lg-5 bg-primary h-100 d-flex align-items-center justify-content-center">
                    <div class="w-100">
                      <div class="mb-4 mb-md-5">
                        <a routerLink="/" class="d-block auth-logo">
                          <img src="assets/images/logo-white.png" alt="" class="logo">
                        </a>
                      </div>

                      <div class="text-white-50 mb-4">
                        <h5 class="text-white">Welcome Back !</h5>
                        <p>Sign in to continue to HotelCard.</p>
                      </div>
                      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">

                        <div class="form-floating form-floating-custom mb-3">
                          <input type="text" class="form-control" id="input-username" formControlName="name"
                                 [ngClass]="{ 'is-invalid': submitted && f['name'].errors }" placeholder="Enter name">
                          <label for="input-username">Username</label>
                          <div class="form-floating-icon">
                            <i class="uil uil-users-alt"></i>
                          </div>
                          @if (submitted && f['name'].errors) {
                            <div class="invalid-feedback" align="left">
                              @if (f['name'].errors['required']) {
                                <div>Username is required</div>
                              }
                            </div>
                          }
                        </div>

                        <div class="form-floating form-floating-custom mb-3">
                          <input type="password" class="form-control" formControlName="password"
                                 [ngClass]="{ 'is-invalid': submitted && f['password'].errors }"
                                 placeholder="Enter password" aria-label="Password" aria-describedby="password-addon">
                          <label for="input-password">Password</label>
                          <div class="form-floating-icon">
                            <i class="uil uil-padlock"></i>
                          </div>
                          @if (submitted && f['password'].errors) {
                            <div class="invalid-feedback" align="left">
                              @if (f['password'].errors['required']) {
                                <span>Password is required</span>
                              }
                            </div>
                          }
                        </div>

                        <div class="form-check form-check-info text-white-50 font-size-16">
                          <input class="form-check-input bg-primary-subtle" type="checkbox" id="remember-check">
                          <label class="form-check-label font-size-14" for="remember-check">
                            Remember me
                          </label>
                        </div>
                        @if (error) {
                          <div class="alert alert-danger mt-3 mb-0">{{error}}</div>
                        }

                        <div class="mt-3">
                          <button class="btn btn-info w-100 auth-form-btn"  type="submit">Log In</button>
                        </div>

                        <div class="mt-4 text-center">
                          <a routerLink="/account/forgot-password" class="text-white-50 text-decoration-underline">Forgot
                            your
                            password?</a>
                        </div>
                      </form><!-- end form -->
                    </div>
                  </div>
                </div><!-- end col -->
              </div><!-- end row -->
            </div><!-- end card -->
            <div class="mt-5 text-center text-muted">
              <p>Don't have an account ? <a routerLink="/account/register" class="fw-medium text-decoration-underline">
                Signup </a></p>
            </div>
          </div>
        </div><!-- end col -->
      </div><!-- end row -->
    </div>
  </div>
  <!-- end container -->
</div>
<!-- end authentication section -->
